<template>
  <div class="container">
    <h2 class="title_css d-block p-3">{{$t('orderpayee.orderpayeeinfo')}}</h2>
    <div class="appt-info shadow my-2">
      <ul class="list-group list-group-flush">
        <li v-if="script_active == 1" class="list-group-item list-group-item-action text-danger">
          <b>警示：该订单已执行过，请确认是否已出帐，避免二次执行。</b>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_Payee">{{ $t('article.trade_no')}}：</label> <input type="text" :value="item.trade_no" id="copy_trade_no" disabled >
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_Payee">{{ $t('orderpayee.bank_account')}}：</label> <input type="text" :value="payee_account.bank_account" id="copy_Payee" disabled >
          <button @click="copy('copy_Payee')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_account">{{ $t('orderpayee.card_no')}}：</label> <input type="text" :value="payee_account.card_no" id="copy_account" disabled >
          <button @click="copy('copy_account')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_bank_name">{{ $t('orderpayee.bank_name')}}：</label> <input type="text" :value="payee_account.bank_name" id="copy_bank_name" disabled >
          <button @click="copy('copy_bank_name')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_sub_bank">{{ $t('orderpayee.sub_bank')}}：</label> <input type="text" :value="payee_account.sub_bank" id="copy_sub_bank" disabled >
          <button @click="copy('copy_sub_bank')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li class="list-group-item list-group-item-action">
          <label for="copy_money">{{ $t('orderpayee.defaultmoney')}}：</label> <input type="text" :value="fmtmoney" disabled >
          <input type="text" :value="defaultmoney" id="copy_money" disabled hidden>
          <button @click="copy('copy_money')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>
        <li v-if="item.inner_ps != null" class="list-group-item list-group-item-action">
          <label for="copy_inner_ps">{{ $t('orderpayee.inner_ps')}}：</label> <input type="text" :value="item.inner_ps" id="copy_inner_ps" disabled >
          <button @click="copy('copy_inner_ps')" class="btn btn-sm btn-dark">{{ $t('orderpayee.copy_btn')}}</button>
        </li>

        <li v-if="item.qrcode_once != null" class="list-group-item list-group-item-action">
          <label for="qrcode_img">{{ $t('orderpayee.payee_qrcode')}}：</label>
          <div class="qrcode" id="qrcode_img">
            <qrcode-vue :value= "item.qrcode_once" :size= "200"></qrcode-vue><br/>
          </div>
        </li>
      </ul>
      <input type="text" id="copy_text" readonly="true" value="">
    </div>

    <div>
      <label :id="'label_trade_'+item.trade_no" v-if="item.status == 0" class="badge ex-btn" :class="[item.voucher_img != ''? 'bg-dark' : 'bg-warning']">
        <h3 :id="'text_trade_'+item.trade_no" v-if="item.voucher_img == ''" class="m-0">{{$t('orderpayee.payforProof')}}</h3>
        <h3 :id="'text_trade_'+item.trade_no" v-if="item.voucher_img != ''" class="m-0">{{$t('OrderListTable.payforProofuped')}}</h3>
        <input style="display:none;" type="file" ref="file" :data-tradeno="item.trade_no" :data-ordertype="item.order_type" @change="fileChange">
      </label>
    </div>
<!--    <div class="" style="height: 12rem;">-->
<!--      <img :src="aliyuncs_url+item.voucher_img" class="card-img-top" alt=""/>-->
<!--    </div>-->
    <div v-for="(imgname,index) in (item.voucher_img || '').split(',')">
      <img :src="aliyuncs_url+imgname" class="card-img-top" alt=""/>
    </div>

    <div id="iscopy" class="toast hide position-fixed bottom-10 start-50 translate-middle-x bg-warning " role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="2000">
      <div class="toast-body">
        {{$t('orderpayee.iscopy')}}
      </div>
  </div>
  </div>
</template>

<script>
import Lang from '../My/Lang.vue';
import { Toast } from 'bootstrap'
import http from "@/js/axios";
import qrcodeVue from 'qrcode.vue';

export default {
  components: {Lang,qrcodeVue},
  props: ["script_active","payee_account","defaultmoney","fmtmoney","item"],
  data() {
    return {
      aliyuncs_url : localStorage.getItem("aliyuncs_url"),
      stickyStatus: '',
      status_bg:'',
    }
  },
  created() {
          // this.$root.$bvToast.toast('1');
  },
  methods: {
      // handledown(){
      //   //获取canvas标签
      //   let canvas = document.getElementsByClassName('qrcode')[0].getElementsByTagName('canvas');
      //   //创建a标签
      //   let a = document.createElement('a');
      //   //获取二维码的url并赋值地址为a.href
      //   a.href = canvas[0].toDataURL('img/png');
      //   //设置下载文件的名字
      //   a.download = '二维码图片';
      //   //点击事件，相当于下载
      //   a.click();
      // },
      copy(copy_txt){
          var input = document.getElementById("copy_text");
          var input_copy_txt = document.getElementById(copy_txt);
          input.value = input_copy_txt.value;
          input.select();
          document.execCommand('Copy');
          let t = new Toast(document.getElementById("iscopy"));
          t.show()
      },
      fileChange(event) {
        let token = localStorage.getItem("token");
        let formdata =  new FormData();
        let tradeno = event.target.getAttribute('data-tradeno');
        let ordertype = event.target.getAttribute('data-ordertype');
        formdata.append('token', token);
        formdata.append('file', event.target.files[0]);
        http.upload("api/common/uploadToAlioss", formdata, (response) => {
          console.log(tradeno);
          console.log(response);
          if(response.data.code == 1){
            // console.log(response.data.data.url);
            let voucher_img = response.data.data.url;
            http.post("api/pet/updatePayforProof", {token: token,tradeno:tradeno,voucher_img:voucher_img,ordertype:ordertype}, (response) => {
              console.log(response);
              let labelel = document.getElementById("label_trade_"+tradeno);
              let textel = document.getElementById("text_trade_"+tradeno);
              labelel.className = 'badge ex-btn bg-dark';
              textel.textContent = this.$t('OrderListTable.payforProofuped');
              let t = new Toast(document.getElementById("isupload"));
              t.show()
            });
          }else{
              // alert(response.data.msg);
            let t = new Toast(document.getElementById("notupload"));
            t.show()
          }
        });
      },
  }
};
</script>

<style scoped lang="scss">
#copy_text{
  position: absolute;
  left: -9999px;
}
.btn{
    vertical-align: top;
    }
input[type="text"]{
    height: 31px;
    padding: 0 10px;
}
.list-group-item label{
  width: 90px;
}
.bottom-10{
  bottom: 10%;
}
@media screen and (max-width: 480px) {
  .list-group-item label{
    width: 100%;
    margin-bottom: 3px;
  }
  .list-group-item input[type="text"]{
    width: 80%;
  }
  .list-group-item button{
    width: 20%;
  }
}
</style>
