import {createI18n} from "vue3-i18n";

const messages = {
    // ######英文
    en: {
        // popup: {confirm: "confirm", title: "tips", message: "welcome！"},
        popup: {confirm: "confirm", title: "Important Notice", message: "Dear members:\n" +
                "To protect your legal rights and interests, please be aware of and comply with the following:\n" +
                "1. When using the services of this platform, you should strictly abide by the laws and regulations of the country/region where you are located;\n" +
                "2. According to relevant laws and regulations, you must fulfill your tax declaration obligations and pay the corresponding taxes in a timely manner for the personal income you obtain through this platform (including but not limited to investment income, commissions, rewards, etc.);\n" +
                "3. This platform does not provide tax, legal consulting or withholding services. It is recommended that you consult a professional tax consultant to ensure compliance.\n" +
                "\n" +
                "Thank you for your understanding and cooperation!"},
        nav: {payfor: "Buy", pay: "Sell", my: "My"},
        header: {
            payfor: "Buy",
            pay: "Sell",
            my: "My",
            payforRecord: "Buy Record",
            payRecord: "Sell Record",
            payforPayee: "Pay",
            assetDetails: "Asset details",
            myTeam: "My Team",
            settings: "Settings",
            refresh: "Refresh",
        },
        set: {lang: "Language", logout: "Logout"},
        // 我的
        usercenter: {
            myInformation: "My Information",
            account: "Account",
            phone: "Phone",
            balance: "Balance",
            credits: "Credits",
            available_credit: "Available credits",
            TodayIncome: "Today's Income",
            MonthlyIncome: "Monthly Income",
            payforRecord: "Buy Record",
            payRecord: "Sell Record",
            assetDetails: "Asset details",
            myTeam: "My Team",
            settings: "Settings",
        },
        // 買入賣出共用
        article: {
            budget: "Budget",
            reservation: "Res",
            bought: "Bought",
            sale: "Sale",
            reservationFrequency: "Res FREQ",
            boughtFrequency: "Bought FREQ",
            status: "Status",
            statusNormal: "Making an reservation ",
            statusPause: "Pause",
            statusAbnormal: "Abnormal",
            statusNoFound: "No Found",
            pet_name: "Contr.Name",
            trade_no: "Contr.No",
            amount: "Contr.Worth",
            createtime: "Res",
            user_in: "Contr.INCO",
            payfor_statusNeg1: "Abandoned",
            payfor_status0: "Buying",
            payfor_status1: "Bought",
            pay_statusNeg1: "Abandoned",
            pay_status0: "Feeding",
            pay_status1: "Sold",
        },
        // 買入紀錄
        OrderListTable: {
            DigitalCurrencyName: "Digital Currency Name",
            trade_no: "Contr.No",
            amount: "Contr.Worth",
            createtime: "Res",
            user_in: "Contr.INCO",
            status: "Status",
            payfor_statusNeg1: "Abandoned",
            payfor_status0: "Buying",
            payfor_status1: "Bought",
            pay_statusNeg1: "Abandoned",
            pay_status0: "Feeding",
            pay_status1: "Sold",
            payforPayee: "Pay",
            payforProof: "Voucher",
            payforProofuped: "Voucher uploaded",
            isupload: "Uploaded",
            notupload: "Not uploaded"
        },
        // 買入-收款人信息
        orderpayee: {
            orderpayeeinfo: "Payee information",
            bank_account: "Bank account",
            card_no: "card no",
            bank_name: "Bank name",
            bank_province: "bank province",
            bank_city: "bank city",
            sub_bank: "Sub bank",
            bank_num: "bank num",
            short: "short",
            defaultmoney: "Amount",
            inner_ps: "postscript",
            copy_btn: "Copy",
            iscopy: "Copied",
            payforProof: "Upload transfer voucher",
            payee_qrcode: "Payee QRCode",
            payee_qrcode_text: "Payee QRCode Text",
        },
        // 資產明細
        MoneyLog: {
            order_num: 'Contr.No',
            createtime: 'Res',
            type: 'Type',
            before: 'Before the change',
            money: 'Amount',
            after: 'After the change',
            //類型
            agentfee: '卖出佣金',                   //支付代理提成
            agentfeeback: '卖出佣金退回',               //支付代理提成退回
            agentfeefor: '买入佣金',                   //代付代理提成
            agentfeeforback: '买入返点退回',               //代付代理提成退回
            disable: '异常冻结',               //冻结金额
            enable: '解除冻结',               //解冻金额
            hand: 'Manual',                   //人工加减款
            pay: '商品卖出',                       //支付订单
            payback: '商品卖出取消',                   //支付失败退回
            payfee: '商品卖出佣金',             //支付手续费
            payfeeback: '商品卖出佣金退回',         //支付手续费退回
            payfor: '商品进货',                       //代付
            payforback: 'Payfor the return',                   //代付退回
            payforcancelback: 'Cancel transaction and payment point refund',       //取消交易代付金额退回
            payforcancelfeeback: 'Cancel transaction and payment commission refund',       //取消交易代付提成退回
            payforcloseback: '商品进货取消金额退回',       //代付关闭金额退回
            payforclosefeeback: '商品进货取消返点退回',   //代付关闭手续费退回
            payforfee: 'Payfor fee',             //代付手续费
            payforfeeback: 'Return the service fee',         //代付手续费退回
            recharge: 'Recharge',                 //充值
            settlement: '每日金额结算',                   //每日结算占位
            withdraw: 'Withdraw',               //提现
            withdrawback: 'Return the failed Withdraw',           //提现失败退回
            profit:'提成',

            //旧的
            // recharge:'Recharge',
            // payfor:'Payfor',
            // payforback:'Payfor the return',
            // payforfee:'Payfor fee',
            // payforcancelback:'Cancel transaction and payment point refund',
            // payforcancelfeeback:'Cancel transaction and payment commission refund',
            // payforfeeback:'Return the service fee ',
            // sell:'Sell',
            // sellback:'Return the failed sell',
            // withdraw:'Withdraw',
            // withdrawback:'Return the failed Withdraw',
            // withdrawfee:'Withdraw fee',
            // withdrawfeeback:'Retuen the Withdraw fee',
            // in:'Transfer in',
            // out:'Transfer out',
            // hand:'Manual',
            // profit:'Profit',
            // profitback:'Return the profit',
            // xprofit:'Share revenue',
            // xprofitback:'Return the share revenue',
        },
        // 我的團隊
        Team: {
            memberLevel1: 'MBR A',
            memberLevel2: 'MBR B',
            memberLevel3: 'MBR C',
        },
        TeamTable: {
            username: 'Account',
            mobile: 'Mobile Number',
            status: 'Status',
            logintime: 'Last login time',
            pid_name: 'Superior',
            status_normal: 'Normal',
            status_hidden: 'Hidden',
        }
    },
    //   ######中文
    zh: {
        popup: {confirm: "确认", title: "重要提示", message: "尊敬的会员：  \n" +
                "为保障您的合法权益，请知悉并遵守以下内容：  \n" +
                "1. 您在使用本平台服务时，应严格遵守所在国家/地区的法律法规要求；  \n" +
                "2. 根据相关法律规定，您通过本平台获得的个人收益（包括但不限于投资收益、佣金、奖励等），需自行履行纳税申报义务，并及时缴纳相应税款；  \n" +
                "3. 本平台不提供税务、法律咨询或代扣代缴服务，建议您咨询专业税务顾问以确保合规。  \n" +
                "\n" +
                "感谢您的理解与配合！  \n" +
                "平台将持续优化服务，与您共同维护合法合规的网络环境。 "},
        nav: {payfor: "买入", pay: "卖出", my: "我的"},
        header: {
            payfor: "买入",
            pay: "卖出",
            my: "我的",
            payforRecord: "买入纪录",
            payRecord: "卖出纪录",
            payforPayee: "付款",
            assetDetails: "资产明细",
            myTeam: "我的团队",
            settings: "设置",
            refresh: "刷新",
        },
        set: {lang: "语言", logout: "注销"},
        // 我的
        usercenter: {
            myInformation: "我的信息",
            account: "帐号",
            phone: "电话",
            balance: "钱包余额",
            credits: "信用额度",
            available_credit: "可用额度",
            TodayIncome: "今日收益",
            MonthlyIncome: "本月收益",
            payforRecord: "买入纪录",
            payRecord: "卖出纪录",
            assetDetails: "资产明细",
            myTeam: "我的团队",
            settings: "设置",
        },
        // 買入賣出共用
        article: {
            budget: "预算金额",
            reservation: "预约金额",
            bought: "已买入金额",
            sale: "已卖出金额",
            reservationFrequency: "预约次数",
            boughtFrequency: "已买入次数",
            saleFrequency: "已卖出次数",
            status: "当前状态",
            statusNormal: "预约中",
            statusPause: "已暂停",
            statusAbnormal: "异常",
            statusNoFound: "查无资料",
            pet_name: "商品名称",
            trade_no: "订单编号",
            amount: "商品价值",
            createtime: "预约时间",
            user_in: "佣金/返点",
            payfor_statusNeg1: "已废弃",
            payfor_status0: "买入中",
            payfor_status1: "已买入",
            pay_statusNeg1: "已废弃",
            pay_status0: "卖出中",
            pay_status1: "已卖出",
        },
        // 買入紀錄
        OrderListTable: {
            DigitalCurrencyName: "",
            trade_no: "订单编号",
            amount: "商品价值",
            createtime: "预约时间",
            user_in: "佣金/返点",
            status: "状态",
            payfor_statusNeg1: "已废弃",
            payfor_status0: "买入中",
            payfor_status1: "已买入",
            pay_statusNeg1: "已废弃",
            pay_status0: "卖出中",
            pay_status1: "已卖出",
            payforPayee: "付款",
            payforProof: "凭证",
            payforProofuped: "凭证已上传",
            isupload: "已上传",
            notupload: "未上传"
        },
        // 買入-收款人信息
        orderpayee: {
            orderpayeeinfo: "收款人信息",
            bank_account: "收款户名",
            card_no: "收款帐号",
            bank_name: "收款银行",
            bank_province: "省份",
            bank_city: "地市",
            sub_bank: "支行分行",
            bank_num: "银行编号",
            short: "银行简称",
            defaultmoney: "支付金额",
            inner_ps: "转账附言",
            copy_btn: "复制",
            iscopy: "已复制",
            payforProof: "上传转账凭证",
            payee_qrcode: "Mã thanh toán",
            payee_qrcode_text: "Văn bản mã thanh toán",
        },
        // 資產明細
        MoneyLog: {
            order_num: '订单编号',
            createtime: '创建时间',
            type: '类型',
            before: '变更前余额',
            money: '变更金额',
            after: '变更后余额',
            //類型
            agentfee: '卖出佣金',                   //支付代理提成
            agentfeeback: '卖出佣金退回',               //支付代理提成退回
            agentfeefor: '买入佣金',                   //代付代理提成
            agentfeeforback: '买入佣金退回',               //代付代理提成退回
            disable: '异常冻结',               //冻结金额
            enable: '解除冻结',               //解冻金额
            hand: '人工处里',                   //人工加减款
            pay: '卖出',                       //支付订单
            payback: '卖出取消',                   //支付失败退回
            payfee: '卖出佣金',             //支付手续费
            payfeeback: '卖出佣金退回',         //支付手续费退回
            payfor: '商品进货',                       //代付
            payforback: '商品进货取消',                   //代付退回
            payforcancelback: '进货取消金额退回',       //取消交易代付金额退回
            payforcancelfeeback: '进货取消返点退回',       //取消交易代付提成退回
            payforcloseback: '商品进货交易关闭金额退回',       //代付关闭金额退回
            payforclosefeeback: '商品进货交易关闭佣金退回',   //代付关闭手续费退回
            payforfee: '进货返点',             //代付手续费
            payforfeeback: '进货返点退回',         //代付手续费退回
            recharge: '充值',                 //充值
            settlement: '每日结算',                   //每日结算占位
            withdraw: '提现',               //提现
            withdrawback: '提现失败退回',           //提现失败退回
            profit:'提成',
            sellback:'卖出失败退回',
        },
        // 我的團隊
        Team: {
            memberLevel1: '一级会员',
            memberLevel2: '二级会员',
            memberLevel3: '三级会员',
        },
        TeamTable: {
            username: '账号',
            mobile: '手机号',
            status: '状态',
            logintime: '最后在线时间：',
            pid_name: '上级账号',
            status_normal: '正常',
            status_hidden: '隐藏',
        }
    },
    //   ######日语
    ja: {
        popup: {confirm: "確認", title: "重要なお知らせ", message: "尊敬する会員の皆様へ：\n" +
                "お客様の正当な権利を保護するため、以下の内容をご確認のうえ、遵守いただきますようお願いいたします：\n" +
                "1. 本プラットフォームのサービスをご利用の際は、お客様の所在国・地域の法令を厳守してください。\n" +
                "2. 関連法規に基づき、本プラットフォームを通じて得た個人収益（投資収益、コミッション、報酬などを含むがこれらに限定されない）については、ご自身で納税申告を行い、適切に税金を納付する義務があります。\n" +
                "3. 本プラットフォームは、税務・法律相談や源泉徴収・納税代行サービスを提供しておりません。適法性を確保するため、専門の税務アドバイザーへの相談をお勧めします。\n" +
                "\n" +
                "ご理解とご協力に感謝いたします。\n" +
                "本プラットフォームは、より良いサービスを提供し、合法かつ適正なネットワーク環境の維持に努めてまいります。"},
        nav: {payfor: "購入", pay: "売却", my: "私の"},
        header: {
            payfor: "購入",
            pay: "売出",
            my: "私の",
            payforRecord: "購入記録",
            payRecord: "売却記録",
            payforPayee: "支払",
            assetDetails: "資産明細",
            myTeam: "私のティーム",
            settings: "設定",
            refresh: "更新",
        },
        set: {lang: "言語", logout: "ログアウト"},
        // 我的
        usercenter: {
            myInformation: "私のインフォメーション",
            account: "アカウント",
            phone: "電話",
            balance: "残高",
            credits: "信用額",
            available_credit: "利用可能額",
            TodayIncome: "今日の収入",
            MonthlyIncome: "今月の収入",
            payforRecord: "購入記録",
            payRecord: "売却記録",
            assetDetails: "資産明細",
            myTeam: "私のティーム",
            settings: "設定",
        },
        // 買入賣出共用
        article: {
            budget: "予算額",
            reservation: "予約額",
            bought: "購入済額",
            sale: "売却済額",
            reservationFrequency: "予約回数",
            boughtFrequency: "購入済回数",
            saleFrequency: "売却済回数",
            status: "現状態",
            statusNormal: "予約中",
            statusPause: "一時停止",
            statusAbnormal: "異常",
            statusNoFound: "未発見",
            pet_name: "商品名称",
            trade_no: "トレード番号",
            amount: "商品額",
            createtime: "予約時間",
            user_in: "コミション/返す点数",
            payfor_statusNeg1: "廃棄済",
            payfor_status0: "購入中",
            payfor_status1: "購入済",
            pay_statusNeg1: "廃棄済",
            pay_status0: "売出中",
            pay_status1: "売却済",
        },
        // 買入紀錄
        OrderListTable: {
            DigitalCurrencyName: "",
            trade_no: "トレード番号",
            amount: "商品額",
            createtime: "予約時間",
            user_in: "コミション/返す点数",
            status: "状態",
            payfor_statusNeg1: "廃棄済",
            payfor_status0: "購入中",
            payfor_status1: "購入済",
            pay_statusNeg1: "廃棄済",
            pay_status0: "売出中",
            pay_status1: "売却済",
            payforPayee: "支払",
            payforProof: "支払証明",
            payforProofuped: "支払証明アップロード済",
            isupload: "アップロード済",
            notupload: "アップロードされていません"
        },
        // 買入-收款人信息
        orderpayee: {
            orderpayeeinfo: "注文の受取人情報",
            bank_account: "集金銀行口座",
            card_no: "集金口座番号",
            bank_name: "集金銀行名",
            bank_province: "都道府県",
            bank_city: "市区町村",
            sub_bank: "支店",
            bank_num: "銀行番号",
            short: "銀行略称",
            defaultmoney: "支払金額",
            inner_ps: "追記",
            copy_btn: "コピー",
            iscopy: "コピー済",
            payforProof: "振替伝票をアップロードする",
        },
        // 資產明細
        MoneyLog: {
            order_num: '受注番号',
            createtime: '作成時間',
            type: 'タイプ',
            before: '変更前の残高',
            money: '変更額',
            after: '変更後の残高',
            //類型
            agentfee: 'エージェントフィー',                   //支付代理提成
            agentfeeback: 'エージェントフィーの払戻',   　//支付代理提成退回
            agentfeefor: '購入エージェントフィー',            //代付代理提成
            agentfeeforback: '購入エージェントフィーの払戻',  //代付代理提成退回
            disable: '無効',               //冻结金额
            enable: '有効 ',               //解冻金额
            hand: '手処理',                   //人工加减款
            pay: '支払',                       //支付订单
            payback: '売却取消',               //支付失败退回
            payfee: '売却フィー',             //支付手续费
            payfeeback: '売却フィー払戻',      //支付手续费退回
            payfor: '仕入',                       //代付
            payforback: '仕入取消',               //代付退回
            payforcancelback: '仕入金払戻',       //取消交易代付金额退回
            payforcancelfeeback: '仕入点数払戻', 　 //取消交易代付提成退回
            payforcloseback: 'クローズバックの支払',       //代付关闭金额退回
            payforclosefeeback: 'クローズバックフォーの支払',  //代付关闭手续费退回
            payforfee: '仕入手数料支払',             //代付手续费
            payforfeeback: '仕入手数料払戻',         //代付手续费退回
            recharge: 'リチャージ',                 //充值
            settlement: '毎日決済',                 //每日结算占位
            withdraw: '引き出す',               //提现
            withdrawback: '引き出す失敗戻る',           //提现失败退回
            profit:'収益',
            sellback:'セルバック',
        },
        // 我的團隊
        Team: {
            memberLevel1: '一級会員',
            memberLevel2: '二級会員',
            memberLevel3: '三級会員',
        },
        TeamTable: {
            username: 'アカウント',
            mobile: '携帯番号',
            status: '状態',
            logintime: 'ログイン時間：',
            pid_name: '上級アカウント',
            status_normal: '正常',
            status_hidden: '非表示',
        }
    },
    //   ######越南语
    vi: {
        popup: {confirm: "xác nhận", title: "Mẹo quan trọng", message: "Kính gửi các thành viên:\n" +
                "Để bảo vệ quyền và lợi ích hợp pháp của bạn, vui lòng lưu ý và tuân thủ những điều sau:\n" +
                "1. Khi sử dụng các dịch vụ của nền tảng này, bạn phải tuân thủ nghiêm ngặt luật pháp và quy định của quốc gia/khu vực nơi bạn cư trú;\n" +
                "2. Theo luật pháp và quy định có liên quan, bạn phải thực hiện nghĩa vụ khai thuế và nộp các loại thuế tương ứng đúng hạn đối với thu nhập cá nhân (bao gồm nhưng không giới hạn ở thu nhập đầu tư, hoa hồng, phần thưởng, v.v.) mà bạn nhận được thông qua nền tảng này;\n" +
                "3. Trang này không cung cấp dịch vụ tư vấn thuế, pháp lý hoặc khấu trừ. Bạn nên tham khảo ý kiến của cố vấn thuế chuyên nghiệp để đảm bảo tuân thủ.\n" +
                "\n" +
                "Cảm ơn sự thông cảm và hợp tác của bạn!"
                // "Trang này sẽ tiếp tục tối ưu hóa các dịch vụ của mình và cùng bạn bắt tay duy trì môi trường mạng hợp pháp và tuân thủ pháp luật."
        },
        nav: {payfor: "mua", pay: "bán", my: "của tôi"},
        header: {
            payfor: "mua",
            pay: "bán",
            my: "của tôi",
            payforRecord: "lịch sử mua",
            payRecord: "lịch sử bán",
            payforPayee: "chi trả",
            assetDetails: "chi tiết tài sản",
            myTeam: "đội của tôi",
            settings: "cài đặt",
            refresh: "tải lại",
        },
        set: {lang: "ngôn ngữ", logout: "đăng xuất"},
        // của tôi
        usercenter: {
            myInformation: "thông tin của tôi",
            account: "tài khoản",
            phone: "số điện thoại",
            balance: "số dư ví",
            credits: "tín dụng",
            available_credit: "hạn mức tín dụng",
            TodayIncome: "thu nhập trong ngày",
            MonthlyIncome: "thu nhập trong tháng",
            payforRecord: "lịch sử mua",
            payRecord: "lịch sử bán",
            assetDetails: "chi tiết tài sản",
            myTeam: "đội của tôi",
            settings: "cài đặt",
        },
        // tương tác mua bán
        article: {
            budget: "số tiền ngân sách",
            reservation: "số tiền đặt trước",
            bought: "số tiền đã mua",
            sale: "số tiền đã bán",
            reservationFrequency: "tần suất đặt trước",
            boughtFrequency: "số lần mua",
            saleFrequency: "số lần bán",
            status: "tình trạng hiện tại",
            statusNormal: "đang đặt",
            statusPause: "đã ngưng",
            statusAbnormal: "bất thường",
            statusNoFound: "không tìm thấy thông tin",
            pet_name: "tên sản phẩm",
            trade_no: "mã đơn",
            amount: "giá trị sản phẩm",
            createtime: "thời gian hẹn",
            user_in: "hoa hồng/giảm giá",
            payfor_statusNeg1: "đã hủy",
            payfor_status0: "đang mua",
            payfor_status1: "đã mua",
            pay_statusNeg1: "đã hủy",
            pay_status0: "đang bán",
            pay_status1: "đã bán",
        },
        // lịch sử mua vào
        OrderListTable: {
            DigitalCurrencyName: "",
            trade_no: "mã đơn",
            amount: "giá trị sản phẩm",
            createtime: "thời gian hẹn",
            user_in: "hoa hồng/giảm giá",
            status: "tình trạng",
            payfor_statusNeg1: "đã hủy",
            payfor_status0: "đang mua",
            payfor_status1: "đã mua",
            pay_statusNeg1: "đã hủy",
            pay_status0: "đang bán",
            pay_status1: "đã bán",
            payforPayee: "chi trả",
            payforProof: "bằng chứng",
            payforProofuped: "bằng chứng đã tải lên",
            isupload: "đã tải lên",
            notupload: "Chưa tải lên"
        },
        // mua-thông tin người nhận
        orderpayee: {
            orderpayeeinfo: "thông tin người nhận",
            bank_account: "tên tài khoản thụ hưởng",
            card_no: "số tài khoản thụ hưởng",
            bank_name: "tên ngân hàng thụ hưởng ",
            bank_province: "tỉnh",
            bank_city: "thành phố",
            sub_bank: "chi nhánh ngân hàng",
            bank_num: "mã ngân hàng",
            short: "tên ngân hàng",
            defaultmoney: "số tiền thanh toán",
            inner_ps: "nội dung",
            copy_btn: "sao chép",
            iscopy: "đã sao chép",
            payforProof: "gửi hóa đơn chuyển tiền",
            payee_qrcode: "Mã thanh toán",
            payee_qrcode_text: "Văn bản mã thanh toán",
        },
        // chi tiết tài sản
        MoneyLog: {
            order_num: 'mã đơn',
            createtime: 'thời gian tạo đơn',
            type: 'kiểu',
            before: 'số dư trước khi thay đổi',
            money: 'số tiền thay đổi',
            after: 'số dư sau khi thay đổi',
            //kiểu
            agentfee: 'hoa hồng bán hàng',
            agentfeeback: 'hoàn tiền hoa hồng bán',
            agentfeefor: 'hoa hồng mua hàng',
            agentfeeforback: 'hoàn tiền hoa hồng mua',
            disable: 'đóng băng bất thường',
            enable: 'giải phóng đóng băng',
            hand: 'xử lý thủ công',
            pay: 'bán ra',
            payback: 'hủy bán ra',
            payfee: 'số tiền bán ra',
            payfeeback: 'hủy số tiền bán ra',
            payfor: 'nhập sản phẩm',
            payforback: 'hủy nhập sản phẩm',
            payforcancelback: 'số tiền hủy hàng được hoàn lại',
            payforcancelfeeback: 'hoàn trả ưu đãi hủy hàng',
            payforcloseback: 'Số tiền đóng giao dịch mua sản phẩm được hoàn lại',
            payforclosefeeback: 'Số tiền hoa hồng đóng giao dịch mua sản phẩm được hoàn lại',
            payforfee: 'ưu đãi nhập hàng',
            payforfeeback: 'hoàn trả ưu đãi nhập hàng',
            recharge: 'nạp tiền',
            settlement: 'quyết toán hàng ngày',
            withdraw: 'rút tiền',
            withdrawback: 'rút tiến that bại hoàn trả',
            profit:'lợi nhuận',
            sellback:'hoàn trả bán ra thất bại',
        },
        // đội của tôi
        Team: {
            memberLevel1: 'hội viên cấp 1',
            memberLevel2: 'hội viên cấp 2',
            memberLevel3: 'hội viên cấp 3',
        },
        TeamTable: {
            username: 'tài khoản',
            mobile: 'số di động',
            status: 'tình trạng',
            logintime: 'lần đăng nhập cuối：',
            pid_name: 'tài khoản cấp trên',
            status_normal: 'bình thường',
            status_hidden: 'ẩn',
        }
    },
};

const i18n = createI18n({
    locale: "en",
    messages: messages,
});

export default i18n;
