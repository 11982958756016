<template>
  
  <Header :page_name="page_name" />
  <div class="home m-auto">
    <div id="loadingIcon" class="d-none justify-content-center  mb-5">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- <a class="dropdown-item text-golden btn" @click="setLocale('zh')">中文</a>
    <a class="dropdown-item text-golden btn" @click="setLocale('en')">English</a> -->
    <article class="mx-auto">
      <router-view
        v-if="isRouterAlive"
        :GlobalData="GlobalData"
        :page_name="page_name"
      ></router-view>
    </article>
  </div>

  <!-- 弹窗组件 -->
  <Transition name="popup">
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <h3>{{ popupTitle }}</h3>
        <button class="close-btn" @click="closePopup">×</button>
      </div>
      <div class="popup-body">
        <p class="message-content">{{ popupMessage }}</p>
      </div>
      <div class="popup-footer">
        <button class="confirm-btn" @click="closePopup">{{$t('popup.confirm')}}</button>
      </div>
    </div>
  </div>
  </Transition>
  <Nav />
</template>

<script>
import Header from "./Template/Header";
import Nav from "./Template/Nav";

export default {
  name: "Index",
  inject: ["setLocale"],
  components: { Header, Nav },
  props: ["page_name"],
  data() {
    return {
      showPopup: true,
      // popupTitle: "系统提示",
      popupTitle: this.$t('popup.title'),
      // popupMessage: "欢迎访问我们的网站！请仔细阅读我们的隐私政策。",
      popupMessage: this.$t('popup.message'),
      GlobalData: {
        //左边 menu 缩展
        boxIsActive: false,
      },
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
    reload() {
      // alert("reload occure")
      console.log("reload occure");
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    setActiveLanguage(lang) {
      localStorage.setItem("language", lang);
    },
    setLang(evt) {
      // console.log(evt)
      const lang = evt.target.dataset.lang;
      this.setActiveLanguage(lang);
      return history.go(0);
    },
  },
  mounted() {
    // // 如果需要延迟显示可以加 setTimeout
    // setTimeout(() => { this.showPopup = true }, 1000);
    let reload = this.reload;
    let isLoading = this.isLoading;

    document.addEventListener("touchstart", (e) => swipeStart(e), false);
    document.addEventListener("touchmove", (e) => swipe(e), false);
    document.addEventListener("touchend", (e) => swipeEnd(e), false);
    const pStart = { x: 0, y: 0 };
    const pCurrent = { x: 0, y: 0 };
    const main = document.querySelector(".home");
    // const main = document.querySelector('body > div');
    let currentMainTransformY = 0;

    function swipeStart(e) {
      if (e.targetTouches) {
        let touch = e.targetTouches[0];
        pStart.x = touch.screenX;
        pStart.y = touch.screenY;
      } else {
        pStart.x = e.screenX;
        pStart.y = e.screenY;
      }

      const getCurrentMainTransformY = () => {
        if (main.style.transform) {
          const regex = /translateY\((.*)px\)/;
          currentMainTransformY = main.style.transform;
          const match = currentMainTransformY.match(regex);
          return parseInt(match[1]);
        } else return 0;
      };
      currentMainTransformY = getCurrentMainTransformY();
      main.style.transition = "10ms ease-in-out";
    }
    function swipe(e) {
      if (e.targetTouches) {
        let touch = e.targetTouches[0];
        pCurrent.x = touch.screenX;
        pCurrent.y = touch.screenY;
      } else {
        pCurrent.x = e.screenX;
        pCurrent.y = e.screenY;
      }
      let changeY = pCurrent.y - pStart.y;
      let totalMainTransformY = currentMainTransformY + changeY;
      let loadingIcon = document.querySelector("#loadingIcon");
      // if (document.body.scrollTop === 0) {
      if (window.scrollY <= 0) {
        // if (totalMainTransformY > 100) {}
        if (totalMainTransformY >= 60) {
          totalMainTransformY = 60;
          isLoading = true;
          loadingIcon.classList.remove("d-none");
          loadingIcon.classList.add("d-flex");
        }
        main.style.transform = `translateY(${totalMainTransformY}px)`;
      }
    }
    function swipeEnd(e) {
      if (isLoading) {
        loading();
      }
    }

    function loading() {
      main.style.transform = `translateY(60px)`;
      /* const node = document.createElement("div");
        const textnode = document.createTextNode("Reload ....");
        node.appendChild(textnode);
        main.appendChild(node); */
// 如果需要延迟显示可以加 setTimeout
//       setTimeout(() => { this.showPopup = true }, 1000);


      setTimeout(() => {
        reload();
        loadingIcon.classList.add("d-none");
        loadingIcon.classList.remove("d-flex");
        main.style.transition = "1s 10ms ease-in-out";
        main.style.transform = ``;
        isLoading = false;
        this.showPopup = true;
      }, 500);
    }
  },
};
</script>

<style lang="scss">

header,
article,
nav {
  max-width: 800px;
}
article {
  min-height: calc(100vh - 116px);
}
.home {
  max-width: 800px;
  //background: url(~@/assets/images/bg4.png) no-repeat center;
  background-image: linear-gradient(to bottom, rgba(170, 234, 236, 0.498), rgba(52, 89, 154, 0.5)), url(~@/assets/images/bg3.png);
  //background-image: linear-gradient(to bottom, rgb(170, 234, 236), rgba(52, 89, 154, 0.5)), url(~@/assets/images/bg3.png);
  background-size: contain;
  padding-top: 65px;
  padding-bottom: 65px;
  background-attachment:fixed;
}
.home {
  max-width: 800px;
  padding-top: 65px;
  padding-bottom: 65px;
}

 .home:before {
   content: "";
   display: block;
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: -10;

   //background: url(~@/assets/images/bg4.png) no-repeat center;
   //background-image: linear-gradient(to bottom, rgb(170, 234, 236), rgba(41, 61, 97, 0.5)), url(~@/assets/images/bg4.png);
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
 }

.title_css {
  background-size: contain;
  text-align: center;
  font-size: 26px;
  color: rgb(255, 194, 52);
  font-weight: 600;
  text-shadow: 1px 1px 3px #000;
}
</style>
<style scoped>
/* 遮罩层过渡动画 */
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.3s ease;
}

.popup-enter-from,
.popup-leave-to {
  opacity: 0;
}

/* 弹窗内容动画 */
.popup-enter-active .popup-container {
  animation: popup-in 0.3s ease;
}

.popup-leave-active .popup-container {
  animation: popup-out 0.2s ease;
}

@keyframes popup-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popup-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
/* 滑动动画 */
@keyframes slide-in {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
}

.popup-enter-active .popup-container {
  animation: slide-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.popup-leave-active .popup-container {
  animation: slide-out 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.popup-container {
  will-change: transform, opacity; /* 提前告知浏览器可能的变化 */
  backface-visibility: hidden;     /* 防止闪烁 */
}
@media (prefers-reduced-motion: reduce) {
  .popup-enter-active,
  .popup-leave-active {
    animation: none !important;
    transition: opacity 0.3s !important;
  }
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.popup-content{
  white-space: pre-line;
  word-break: break-word;
}
.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.popup-body {
  margin-bottom: 20px;
}

.popup-footer {
  text-align: right;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #0056b3;
}
</style>